import { Button } from '@chakra-ui/react';
import { forwardRef } from 'react';

const PrimaryButton = forwardRef(({ children, ...props }, ref) => {
  return (
    <Button
      borderRadius="2px"
      color="primary.100"
      colorScheme="yellow"
      letterSpacing="primary.tight"
      py="12px"
      px="30px"
      sx={{ fontVariantCaps: 'all-small-caps' }}
      ref={ref}
      {...props}
    >
      {children}
    </Button>
  );
});

export default PrimaryButton;
