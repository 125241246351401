import { FormLabel } from '@chakra-ui/react';

export default function Label({ children, ...rest }) {
  return (
    <FormLabel
      color="secondary.400"
      letterSpacing="secondary.normal"
      mb="2px"
      sx={{ fontVariantCaps: 'all-small-caps' }}
      w="max-content"
      {...rest}
    >
      {children}
    </FormLabel>
  );
}
