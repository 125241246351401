import PrimaryButton from '@/components/ui/PrimaryButton';
import Label from '@/components/ui/text/Label';
import {
  Button,
  Center,
  Container,
  Flex,
  FormControl,
  Heading,
  Input,
  Box,
  Image,
  VStack,
  Link,
  Text,
  Alert,
  AlertIcon,
  AlertDescription,
} from '@chakra-ui/react';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

export default function Login() {
  const router = useRouter();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  useEffect(() => {
    if (router.query.error) setError('Your email or password is incorrect');
  }, [router.query.error]);

  const onSubmit = async (form) => {
    try {
      setLoading(true);
      const { email, password } = form;
      await signIn('credentials', { email, password });
    } catch (error) {
      setLoading(false);
      setError(error?.message);
    }
  };

  return (
    <Box bg="#008FC7">
      <Box
        bgImage="/stripe-background.png"
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
      >
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Container maxW="sm">
            <Center h="100vh" d="flex" flexDir="column">
              <Image mb="60px" src="/logo.png" alt="vivid" width="158px" />
              {error !== null && (
                <Alert status="error" mb={5}>
                  <AlertIcon />
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}
              <FormControl>
                <Flex flexDir="column">
                  <VStack spacing={8}>
                    <VStack spacing={4} w="full">
                      <VStack spacing={1} alignItems="flex-start" w="full">
                        <Label color="white" opacity={0.7}>
                          Email
                        </Label>
                        <Input
                          bg="white"
                          placeholder="email"
                          {...register('email')}
                        />
                      </VStack>
                      <VStack spacing={1} alignItems="flex-start" w="full">
                        <Label color="white" opacity={0.7}>
                          Password
                        </Label>
                        <Input
                          bg="white"
                          placeholder="password"
                          type="password"
                          {...register('password')}
                        />
                      </VStack>
                    </VStack>
                    <PrimaryButton
                      isLoading={isLoading}
                      isDisabled={isLoading}
                      type="submit"
                    >
                      log in
                    </PrimaryButton>
                    <Link
                      href="/forgot-password"
                      color="white"
                      opacity={0.75}
                      fontWeight="bold"
                    >
                      <Text fontWeight="bold" letterSpacing="secondary.normal">
                        FORGOT PASSWORD?
                      </Text>
                    </Link>
                  </VStack>
                </Flex>
              </FormControl>
            </Center>
          </Container>
        </form>
      </Box>
    </Box>
  );
}
