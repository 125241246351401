import { getCsrfToken, getProviders, getSession } from 'next-auth/react';
import Login from '@/screens/login/Login';

export default function Home() {
  return <Login />;
}

export const getServerSideProps = async (context) => {
  const { req } = context;
  const session = await getSession({ req });
  const loggedInUser = session?.user || null;

  if (loggedInUser) {
    return {
      redirect: {
        destination: '/dashboard',
        permanent: false,
      },
    };
  }

  return {
    props: {
      session: null,
      providers: (await getProviders(context)) || null,
      csrfToken: (await getCsrfToken(context)) || null,
    },
  };
};
